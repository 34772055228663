.navbar{
    width: 100%;
    height: 75px;
    background: white;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
}
/* #282fcd */

.navbar a{
    color: black;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
}

.navbar a:hover{
  color: #3e497a;
}

.toggleButton {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.toggleButton svg {
    font-size: 50px;
}

.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: #3e497a;
    cursor: pointer;
  }


#open {
  height: 100vh;
}

#open .links {
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 100px;
}

#open a {
  width: 100%;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .navbar a {
    width: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .toggleButton {
    display: flex;
    
  }
  #close a {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .toggleButton {
    display: none;
    
  }

  .hiddenLinks {
    display: none;
  }

  #open {
    transition: 0.5s;
    
  }

  #open .links {
    flex-direction: row;
    
  }
}