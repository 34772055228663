
.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .projects .projectTitle {
    text-align: center;
    color: #3e497a;
    font-size: 60px;
    font-weight: bolder;
  }
  
  .projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
  }
  
  /* MENU ITEM STYLING */
  
  .projectItem {
    animation: fadeInAnimation ease .6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    border-radius: 10px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 5px 10px #dddddd;
    text-align: center;
    background-color: white;
    

    
  }

  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }

  }



  .projectItem:hover {
 
    transition: 0.15s ease-in;

    cursor: pointer;
    transform: scale(1.05);

  }
  
  .projectItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .projectItem h1 {
    font-size: 25px;
  }
  
  @media only screen and (max-width: 1300px) {
    .projectList {
      grid-template-columns: 1fr 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      grid-template-columns: 1fr;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .projectList {
      width: 100%;
    }
    .projectItem {
      width: 300px;
      height: 300px;
    }
  }